:root {
  --light-bg: #DCE1DE;
  --dark-bg: #051923;
  --orange: #FE5F00;
  --gray: #ACBDBA;
  --green: #A2D729;
  background-color: var(--dark-bg);
}

.rules-dialog a:focus {
 outline: none ;
}

.config-panel canvas {
  border-radius: 5px;
}

.logo-beta {
  border-radius: 20px;
  background-color: #C75000;
  padding: 3px 8px 5px;
}

.config-panel {
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.config-panel .mdc-list-divider {
  margin: 16px -16px 16px -16px;
}

.mdc-dialog__content .mdc-list-divider {
  margin: 8px -24px 8px -24px;
}

.config-panel .mdc-card {
  width: 95vw;
  max-width: 500px;
}

.config-panel .rmwc-data-table {
  width: 100%;
  max-width: 500px;
}

.config-panel .rmwc-data-table__content {
  width: 100%;
}

.config-panel .mdc-text-field {
  width: 100%;
}

.spaced {
  margin: 5px 0px;
}

.centered {
  text-align: center;
}

.light-bg {
  background-color: var(--light-bg);
}

/* RMWC */

.mdc-floating-label {
  color: #003553 !important;
}

.mdc-linear-progress__bar-inner {
  background-color: #00A6FB;
}

/* buffer dots show up on mobile even when buffer not set */
.mdc-linear-progress {
  background-color: var(--light-bg);
}
.mdc-linear-progress__buffering-dots {
  background-image: unset;
}

.mdc-card {
  padding: 16px;
}

/* App */

.App {
  height: calc(var(--vh, 1vh) * 100);
}

.logo {
  color: white;
  margin-top: 20px;
}

/* PlayGame */

.mdc-dialog__actions a {
  margin: 0px 5px;
  cursor: pointer;
}

.icon-vertical-shift {
  position: relative;
  top: 7px;
}

.game-bar {
  position: fixed;
  right: -5px;
  top: 5px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white;
}

.game-bar .bar-button {
  margin: 10px;
}

.game-bar .bar-button i {
  cursor: pointer;
}

.auction-box {
  margin: 3px;
  padding: 5px 5px 0px;
  border-radius: 3px;
}

.auction-box button {
  margin-right: 8px;
}

.auction-winner {
  margin: 0px 0px 5px 5px;
}

.auction-box .auction-notes {
  margin: 5px 0px;
}

.component-auction {
  background-color: var(--gray);
}

.component-auction.winning {
  background-color: var(--green);
}

.sacrifice-auction {
  background-color: var(--orange);
}

.sacrifice-auction.winning {
  background-color: var(--green);
}

.rules-dialog a:link, a:visited {
    color: #85929E;
}

.rules-dialog a:hover {
    color: #85929E;
}

/* CreateGame */

.coil-info {
  color: white;
}

.coil-info img {
  position: relative;
  top: 4px;
  width: 20px;
}

.coil-info a {
    text-decoration: none;
}

.coil-info a:link, a:visited {
    color: #85929E;
}

.coil-info a:hover {
    color: #85929E;
}

.feedback-info {
  color: white;
}

.feedback-info a {
    text-decoration: none;
}

.feedback-info a:link, a:visited {
    color: #85929E;
}

.feedback-info a:hover {
    color: #85929E;
}

.create-buttons hr {
  border: 1px solid white;
  width: 100px;
}

/* MessagesView */

.messages-body {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.messages-list {
  flex-grow: 1;
  overflow-y: scroll;
  margin-right: -50px;
  padding-right: 50px;
  background-color: #505A5B;
}

.messages-controls .mdc-select {
  width: 100%;
}

.messages-controls .mdc-text-field {
  width: 100%;
}

.messages-controls .mdc-text-field i:focus {
 outline: none !important;
}

.message-bubble {
  border: 0px solid black;
  border-radius: 5px;
  padding: 10px;
  max-width: 400px;
  position: relative;
}

.own-message-bubble {
  margin: 5px 5px 20px 50px;
  color: black;
  background-color: var(--green);
}

.own-message-bubble::after {
    border-left: 15px solid transparent;
    border-top: 15px solid var(--green);
    bottom: -15px;
    content: "";
    position: absolute;
    right: 20px;
}

.other-message-bubble {
  margin: 5px 50px 20px 5px;
  color: black;
  background-color: white;
}

.other-message-bubble::after {
    border-left: 15px solid white;
    border-bottom: 15px solid transparent;
    bottom: -15px;
    content: "";
    position: absolute;
    left: 20px;
}

/* Dead */

.player-dead {
  margin-top: 50px;
}

.player-dead-character {
  margin-top: 50px;
}

.player-dead-character .head {
  position: relative;
  left: 40px;
  top: 60px;
}

.player-dead-character .wrench {
  position: relative;
  right: 40px;
}

.player-dead-character .material-icons {
  font-size: 150px;
  color: white;
}

.dead-animate i {
  animation: rotate 20s linear infinite;
}
@keyframes rotate {
  to{ transform: rotate(360deg); }
}

.dead-animate {
  animation: shrink 10s linear;
  animation-fill-mode: forwards;
}
@keyframes shrink {
  to{ transform: scale(0); }
}

/* Location */

.room-info {
  background-color: var(--gray);
  margin: 5px 0px;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
}

.room-info-row {
  display: flex;
}

.room-info-status {
  background-color: var(--orange);
  border-radius: 20px;
  margin: 0px 2px;
  padding: 2px 5px 5px;
}

.room-info-player {
  background-color: var(--gray);
  border-radius: 20px;
  margin: 0px 2px;
  padding: 2px 5px 5px;
}

.room-info-middle {
  flex-grow: 1;
}

.room-info-tags {
  padding: 5px 0px;
}

.room-info-arrow {
  margin: 9px 10px 0px 0px;
  width: 25px;
}

/* Players */
.player-info {
  background-color: var(--gray);
  margin: 5px 0px;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
}

.current-player-info {
  background-color: var(--green);
}

.player-info-row {
  display: flex;
}

.player-info-player {
  background-color: var(--gray);
  border-radius: 20px;
  margin: 0px 2px;
  padding: 2px 5px 5px;
}

.player-info-statuses {
  display: inline-flex;
  align-items: center;
}

.player-info-middle {
  flex-grow: 1;
}

.player-info-tags {
  padding: 5px 0px;
}

.player-info-name {
  display: inline-flex;
  align-items: center;
}

.player-info-ready {
  display: inline-flex;
  align-items: center;
}

.player-info-name.dead {
  text-decoration: line-through;
}

.player-info-airlock{
  margin: 9px 10px 0px 0px;
  width: 25px;
}

/* Chat */

.mdc-drawer--open {
  width: 90vw;
}